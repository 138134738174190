import { makeStyles } from '@material-ui/core';
import React from 'react';
import LogoFullPNG from './logo.png';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (<img src={LogoFullPNG} alt='Stateful' 
  className={classes.svg} />);
};

export default LogoIcon;
