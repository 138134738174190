import { Content, Page } from '@backstage/core-components';
import {
  HomePageStarredEntities,
  HomePageToolkit
} from '@backstage/plugin-home';

import { HomePageSearchBar } from '@backstage/plugin-search';
import {
  SearchContextProvider
} from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import CategoryIcon from '@material-ui/icons/Person';
import React from 'react';



const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none'
  }
}));


export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid container item xs={12} justifyContent='center'>
              <HomePageSearchBar
                InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline }}}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[{
                    url: 'catalog?filters[kind]=user',
                    label: 'Users',
                    icon: <CategoryIcon />,
                  }]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
